// App.js
import React, { useState, useEffect } from 'react';
import './App.css';
import { FaTrashAlt, FaPlusCircle } from 'react-icons/fa';

// ---------- Utility Functions ----------
const formatCurrency = (value) => {
  const num = parseFloat(value.replace(/[$,]/g, ''));
  return isNaN(num) ? '' : `$${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

const formatPercentage = (value) => {
  const num = parseFloat(value.replace(/[%]/g, ''));
  return isNaN(num) ? '' : `${num.toFixed(2)}%`;
};

const parseCurrency = (value) => parseFloat(value.replace(/[$,]/g, '')) || 0;

// ---------- Hardcoded Year & 401(k) Limit ----------
// Update these two values in ONE place each year
const CURRENT_YEAR = 2025;
const HARDCODED_401K_LIMIT = 23500;

// ---------- Input Components ----------
const InputField = ({ value, onChange, placeholder, type = 'text', formatFn, maxDecimals }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleBlur = () => {
    let val = inputValue.replace(/[^0-9.]/g, '');
    if (maxDecimals !== undefined) {
      const parts = val.split('.');
      if (parts.length > 2 || (parts[1] && parts[1].length > maxDecimals)) {
        setInputValue(value);
        return;
      }
    }
    if (formatFn) {
      val = formatFn(val);
    }
    onChange(val);
  };

  return (
    <input
      type={type}
      className="input"
      value={inputValue}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      required
    />
  );
};

const TextInput = ({ value, onChange, placeholder }) => (
  <input
    type="text"
    className="input"
    value={value}
    onChange={e => onChange(e.target.value)}
    placeholder={placeholder}
    required
  />
);

const SelectInput = ({ value, onChange, options }) => (
  <div className="select is-fullwidth">
    <select className="input" value={value} onChange={e => onChange(e.target.value)} required>
      {options.map(option => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  </div>
);

// ---------- Table Row Component ----------
const Row = ({ row, handleInputChange, removeRow, fields }) => (
  <tr>
    {fields.map((field, index) => {
      const { name, component: Component, placeholder, options } = field;
      return (
        <td key={index}>
          {row.readOnly ? (
            <span>{row[name]}</span>
          ) : (
            <Component
              row={row}
              value={row[name]}
              onChange={(val) => handleInputChange(name, val)}
              placeholder={placeholder}
              options={options}
            />
          )}
        </td>
      );
    })}
    <td>
      {!row.readOnly && (
        <button type="button" className="button is-danger is-small" onClick={removeRow}>
          <FaTrashAlt />
        </button>
      )}
    </td>
  </tr>
);

// ---------- Section Table Component ----------
const SectionTable = ({
  title,
  rows,
  addRow,
  handleInputChange,
  removeRow,
  fields,
  totalLabel,
  totalValue
}) => (
  <div className="card mb-5">
    <header className="card-header">
      <p className="card-header-title is-size-5">{title}</p>
    </header>
    <div className="card-content">
      <div className="table-container">
        <table className="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              {fields.map((field, i) => (
                <th key={i}>{field.label}</th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <Row
                key={rowIndex}
                row={row}
                handleInputChange={(name, val) => handleInputChange(rowIndex, name, val)}
                removeRow={() => removeRow(rowIndex)}
                fields={fields}
              />
            ))}
          </tbody>
        </table>
      </div>
      <button className="button is-primary" onClick={addRow}>
        <FaPlusCircle /> &nbsp;Add
      </button>
      <div className="mt-4">
        <strong>{totalLabel}:</strong> ${totalValue}
      </div>
    </div>
  </div>
);

// ---------- useRows Hook ----------
function useRows(initialRow) {
  const [rows, setRows] = useState([initialRow]);

  const handleInputChange = (index, field, value) => {
    setRows(prev => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  // Use the original blank row each time, so new rows are empty
  const addRow = () => {
    setRows(prev => [...prev, { ...initialRow }]);
  };

  const removeRow = (index) => {
    setRows(prev => (prev.length > 1 ? prev.filter((_, i) => i !== index) : prev));
  };

  return [rows, setRows, handleInputChange, addRow, removeRow];
}

// ---------- Calculation Helpers ----------
const calculateTotal = (rows, isMonthly = true) =>
  rows
    .reduce((acc, row) => {
      const amount = parseCurrency(row.amount || row.currentValue || row.balance || '0');
      return acc + (isMonthly && row.frequency === 'monthly' ? amount : isMonthly ? amount / 12 : amount);
    }, 0)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');

const calculatePayPeriodsRemaining = (payFrequency, lastPayDate) => {
  if (!payFrequency || !lastPayDate) return 0;
  const lastPay = new Date(lastPayDate);
  const endOfYear = new Date(lastPay.getFullYear(), 11, 31);
  const msInDay = 1000 * 60 * 60 * 24;
  const daysRemaining = Math.ceil((endOfYear - lastPay) / msInDay);
  const payPeriodsMap = { biweekly: 14, 'semi-monthly': 15, monthly: 30 };
  const daysPerPeriod = payPeriodsMap[payFrequency] || 30;
  const payPeriodsRemaining = Math.floor(daysRemaining / daysPerPeriod);
  return payPeriodsRemaining > 0 ? payPeriodsRemaining : 0;
};

function App() {
  // ---------- Row Hooks ----------
  const [incomeRows, setIncomeRows, handleIncomeChange, addIncomeRow, removeIncomeRow] = useRows({
    name: '',
    amount: '',
    frequency: 'monthly'
  });
  const [expenseRows, setExpenseRows, handleExpenseChange, addExpenseRow, removeExpenseRow] = useRows({
    name: '',
    amount: '',
    frequency: 'monthly'
  });
  const [assetRows, setAssetRows, handleAssetChange, addAssetRow, removeAssetRow] = useRows({
    name: '',
    accountType: '',
    currentValue: '',
    monthlyDeposit: '',
    interest: ''
  });
  const [debtRows, setDebtRows, handleDebtChange, addDebtRow, removeDebtRow] = useRows({
    name: '',
    balance: '',
    interest: '',
    monthlyPayment: ''
  });

  // ---------- Summaries ----------
  const [totalMonthlyIncome, setTotalMonthlyIncome] = useState('0.00');
  const [totalMonthlyExpense, setTotalMonthlyExpense] = useState('0.00');
  const [totalAssets, setTotalAssets] = useState('0.00');
  const [totalDebts, setTotalDebts] = useState('0.00');

  // ---------- Pay Check & 401(k) ----------
  const [payFrequency, setPayFrequency] = useState('biweekly');
  const [lastPayDate, setLastPayDate] = useState('');
  const [lastGrossIncome, setLastGrossIncome] = useState('');
  const [lastNetIncome, setLastNetIncome] = useState('');
  const [last401kContribution, setLast401kContribution] = useState('');
  const [ytd401kContribution, setYtd401kContribution] = useState('');
  const [employerMatch, setEmployerMatch] = useState('');
  const [contributionPercentage, setContributionPercentage] = useState('--');
  const [expectedTotalContribution, setExpectedTotalContribution] = useState('--');
  const [payPeriodsRemaining, setPayPeriodsRemaining] = useState('--');

  // ---------- PTO & Sick ----------
  const [ptoHoursAdded, setPtoHoursAdded] = useState('');
  const [currentPtoHours, setCurrentPtoHours] = useState('');
  const [sickHoursAdded, setSickHoursAdded] = useState('');
  const [currentSickHours, setCurrentSickHours] = useState('');
  const [totalPtoHours, setTotalPtoHours] = useState('--');
  const [totalSickHours, setTotalSickHours] = useState('--');
  const [totalPtoDays, setTotalPtoDays] = useState('--');
  const [totalSickDays, setTotalSickDays] = useState('--');
  const [totalDays, setTotalDays] = useState('--');

  // ---------- User Credentials & Modal ----------
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [confirmationCallback, setConfirmationCallback] = useState(null);

  // ---------- Tab Navigation ----------
  const [currentTab, setCurrentTab] = useState(0);

  // ---------- Summaries on row changes ----------
  useEffect(() => {
    setTotalMonthlyIncome(calculateTotal(incomeRows));
  }, [incomeRows]);

  useEffect(() => {
    setTotalMonthlyExpense(calculateTotal(expenseRows));
  }, [expenseRows]);

  useEffect(() => {
    setTotalAssets(calculateTotal(assetRows, false));
  }, [assetRows]);

  useEffect(() => {
    setTotalDebts(calculateTotal(debtRows, false));
  }, [debtRows]);

  // ---------- Recompute on pay check changes ----------
  useEffect(() => {
    update401kSummary();
    updateNetIncome();
    updatePtoSickSummary();
  }, [
    payFrequency,
    lastPayDate,
    lastGrossIncome,
    lastNetIncome,
    last401kContribution,
    ytd401kContribution,
    ptoHoursAdded,
    currentPtoHours,
    sickHoursAdded,
    currentSickHours
  ]);

  // ---------- 401(k) Summary ----------
  const update401kSummary = () => {
    if (!lastPayDate || !payFrequency) {
      setContributionPercentage('--');
      setExpectedTotalContribution('--');
      setPayPeriodsRemaining('--');
      return;
    }
    const periods = calculatePayPeriodsRemaining(payFrequency, lastPayDate);
    if (!lastGrossIncome || !last401kContribution || !ytd401kContribution) {
      setContributionPercentage('--');
      setExpectedTotalContribution('--');
      setPayPeriodsRemaining(periods.toString());
      return;
    }
    const gross = parseCurrency(lastGrossIncome);
    const contrib = parseCurrency(last401kContribution);
    const ytd = parseCurrency(ytd401kContribution);
    if (gross === 0 || contrib === 0) {
      setContributionPercentage('--');
      setExpectedTotalContribution('--');
      setPayPeriodsRemaining(periods.toString());
      return;
    }
    const pct = (contrib / gross) * 100;
    const expected = ytd + contrib * periods;
    setContributionPercentage(pct.toFixed(2));
    setExpectedTotalContribution(
      expected.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    );
    setPayPeriodsRemaining(periods.toString());
  };

  // ---------- Net Income => first row in incomes ----------
  const updateNetIncome = () => {
    if (!lastNetIncome) return;
    const netIncome = parseCurrency(lastNetIncome);
    let monthlyNet = netIncome;

    if (payFrequency === 'biweekly') {
      monthlyNet = (netIncome * 26) / 12;
    } else if (payFrequency === 'semi-monthly') {
      monthlyNet = netIncome * 2;
    }

    const updated = [...incomeRows];
    updated[0] = {
      name: 'Income from Pay Check',
      amount: formatCurrency(monthlyNet.toFixed(2)),
      frequency: 'monthly',
      readOnly: true
    };
    setIncomeRows(updated);
  };

  // ---------- PTO & Sick Summaries ----------
  const updatePtoSickSummary = () => {
    if (
      !ptoHoursAdded ||
      !currentPtoHours ||
      !sickHoursAdded ||
      !currentSickHours ||
      !lastPayDate ||
      !payFrequency
    ) {
      setTotalPtoHours('--');
      setTotalSickHours('--');
      setTotalPtoDays('--');
      setTotalSickDays('--');
      setTotalDays('--');
      return;
    }
    const periods = calculatePayPeriodsRemaining(payFrequency, lastPayDate);
    const pto = parseFloat(currentPtoHours) + parseFloat(ptoHoursAdded) * periods;
    const sick = parseFloat(currentSickHours) + parseFloat(sickHoursAdded) * periods;
    setTotalPtoHours(pto.toFixed(2));
    setTotalSickHours(sick.toFixed(2));
    setTotalPtoDays((pto / 8).toFixed(2));
    setTotalSickDays((sick / 8).toFixed(2));
    setTotalDays(((pto + sick) / 8).toFixed(2));
  };

  // ---------- API Interaction Functions ----------
  // 1) Verify credentials by sending username/password to /loadData
  const verifyCredentials = async (username, password) => {
    try {
      const response = await fetch('/loadData', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        const errorText = await response.text();
        return {
          success: false,
          message: errorText.includes('Incorrect password') ? 'Incorrect password' : 'No user found'
        };
      }

      const data = await response.json();
      return data ? { success: true, data } : { success: false, message: 'No user found' };

    } catch (error) {
      return { success: false, message: `Failed to verify credentials: ${error.message}` };
    }
  };

  // 2) Handle Save (verifies user, then calls saveData)
  const handleSave = async () => {
    const { success, message } = await verifyCredentials(username, password);
    if (success || message === 'No user found') {
      if (message !== 'No user found') {
        setModalMessage(
          <>
            Existing user found.
            <br />
            Do you want to overwrite the data?
          </>
        );
        setConfirmationCallback(() => saveData);
        setShowModal(true);
      } else {
        saveData();
      }
    } else {
      showAlert(message);
    }
  };

  // Actually Save Data to /saveData
  const saveData = async () => {
    try {
      const payload = {
        username,
        password,
        data: {
          incomeRows,
          expenseRows,
          assetRows,
          debtRows,
          payFrequency,
          lastPayDate,
          lastGrossIncome,
          lastNetIncome,
          last401kContribution,
          ytd401kContribution,
          employerMatch,
          ptoHoursAdded,
          currentPtoHours,
          sickHoursAdded,
          currentSickHours
        }
      };

      const res = await fetch('/saveData', {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-Type': 'application/json' }
      });

      if (!res.ok) {
        const errorText = await res.text();
        showAlert(`Failed to save data: ${errorText}`);
      } else {
        showAlert('Data saved successfully');
      }

    } catch (err) {
      showAlert(`Failed to save data: ${err.message}`);
    }
  };

  // 3) Handle Delete (verifies user, then calls deleteUser)
  const handleDelete = async () => {
    const { success, message } = await verifyCredentials(username, password);
    if (success) {
      setModalMessage('Are you sure you want to delete your user account and all associated data?');
      setConfirmationCallback(() => deleteUser);
      setShowModal(true);
    } else {
      showAlert(message);
    }
  };

  // Actually Delete User via /deleteUser
  const deleteUser = async () => {
    try {
      const res = await fetch('/deleteUser', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' }
      });

      if (!res.ok) {
        const errorText = await res.text();
        showAlert(`Failed to delete user: ${errorText}`);
      } else {
        showAlert('User deleted successfully');
        setUsername('');
        setPassword('');
      }

    } catch (err) {
      showAlert(`Failed to delete user: ${err.message}`);
    }
  };

  // 4) Handle Check User (for "Load Data" button)
  const handleCheckUser = async () => {
    const { success, message } = await verifyCredentials(username, password);
    if (success) {
      setModalMessage(
        <>
          Existing user found.
          <br />
          Loading data will overwrite any data
          <br />
          that has been entered.
          <br />
          Do you want to proceed?
        </>
      );
      setConfirmationCallback(() => loadData);
      setShowModal(true);
    } else {
      showAlert(message);
    }
  };

  // Actually Load Data from /loadData
  const loadData = async () => {
    try {
      const response = await fetch('/loadData', {
        method: 'POST',
        body: JSON.stringify({ username, password }),
        headers: { 'Content-Type': 'application/json' }
      });

      if (!response.ok) {
        const errorText = await response.text();
        showAlert(`Failed to load data: ${errorText}`);
      } else {
        const data = await response.json();

        // Update states from loaded data
        setIncomeRows(data.incomeRows || []);
        setExpenseRows(data.expenseRows || []);
        setAssetRows(data.assetRows || []);
        setDebtRows(data.debtRows || []);
        setPayFrequency(data.payFrequency || 'biweekly');
        setLastPayDate(data.lastPayDate || '');
        setLastGrossIncome(data.lastGrossIncome || '');
        setLastNetIncome(data.lastNetIncome || '');
        setLast401kContribution(data.last401kContribution || '');
        setYtd401kContribution(data.ytd401kContribution || '');
        setEmployerMatch(data.employerMatch || '');
        setPtoHoursAdded(data.ptoHoursAdded || '');
        setCurrentPtoHours(data.currentPtoHours || '');
        setSickHoursAdded(data.sickHoursAdded || '');
        setCurrentSickHours(data.currentSickHours || '');

        showAlert('Data loaded successfully!');
      }

    } catch (err) {
      showAlert(`Failed to load data: ${err.message}`);
    }
  };

  // Show alert in modal
  const showAlert = (message) => {
    setModalMessage(message);
    setConfirmationCallback(null);
    setShowModal(true);
  };

  // Modal confirm/cancel
  const handleModalConfirm = () => {
    if (confirmationCallback) {
      confirmationCallback();
    }
    setShowModal(false);
  };
  const handleModalCancel = () => setShowModal(false);

  // ---------- Tabs ----------
  const tabs = [
    {
      title: 'Pay Check',
      content: (
        <>
          {/* ---- Card #1: Pay Check Details ---- */}
          <div className="card mb-5">
            <header className="card-header">
              <p className="card-header-title is-size-5">Pay Check</p>
            </header>
            <div className="card-content">
              {/* Pay Frequency */}
              <div className="field">
                <label className="label">Pay Frequency:</label>
                <SelectInput
                  value={payFrequency}
                  onChange={setPayFrequency}
                  options={[
                    { value: 'biweekly', label: 'Biweekly' },
                    { value: 'semi-monthly', label: 'Semi-monthly' },
                    { value: 'monthly', label: 'Monthly' }
                  ]}
                />
              </div>

              {/* Last Pay Date */}
              <div className="field">
                <label className="label">Last Pay Date:</label>
                <input
                  type="date"
                  className="input"
                  value={lastPayDate}
                  onChange={e => setLastPayDate(e.target.value)}
                />
              </div>

              {/* Last Gross Income */}
              <div className="field">
                <label className="label">Last Gross Income:</label>
                <InputField
                  value={lastGrossIncome}
                  onChange={setLastGrossIncome}
                  placeholder="$"
                  formatFn={formatCurrency}
                  maxDecimals={2}
                />
              </div>

              {/* Last Net Income */}
              <div className="field">
                <label className="label">Last Net Income:</label>
                <InputField
                  value={lastNetIncome}
                  onChange={setLastNetIncome}
                  placeholder="$"
                  formatFn={formatCurrency}
                  maxDecimals={2}
                />
              </div>

              {/* Last 401(k) Contribution */}
              <div className="field">
                <label className="label">Last 401(k) Contribution:</label>
                <InputField
                  value={last401kContribution}
                  onChange={setLast401kContribution}
                  placeholder="$"
                  formatFn={formatCurrency}
                  maxDecimals={2}
                />
              </div>

              {/* YTD 401(k) Contribution */}
              <div className="field">
                <label className="label">YTD 401(k) Contribution:</label>
                <InputField
                  value={ytd401kContribution}
                  onChange={setYtd401kContribution}
                  placeholder="$"
                  formatFn={formatCurrency}
                  maxDecimals={2}
                />
              </div>

              {/* Employer Match */}
              <div className="field">
                <label className="label">Employer Match (%):</label>
                <InputField
                  value={employerMatch}
                  onChange={setEmployerMatch}
                  placeholder="%"
                  formatFn={formatPercentage}
                  maxDecimals={2}
                />
              </div>

              <hr />
              <h2 className="title is-5">401(k) Summary</h2>
              <p>Contribution percentage: <strong>{contributionPercentage}%</strong></p>
              <p>Expected total 401(k) contribution: <strong>${expectedTotalContribution}</strong></p>
              <p className="is-size-7 has-text-grey">
                The {CURRENT_YEAR} 401(k) limit is: ${HARDCODED_401K_LIMIT.toLocaleString()}
              </p>
              <p>Pay periods remaining: <strong>{payPeriodsRemaining}</strong></p>
            </div>
          </div>

          {/* ---- Card #2: PTO & Sick Hours ---- */}
          <div className="card mb-5">
            <header className="card-header">
              <p className="card-header-title is-size-5">PTO &amp; Sick Hours</p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">PTO hours added:</label>
                <InputField
                  value={ptoHoursAdded}
                  onChange={setPtoHoursAdded}
                  placeholder="0"
                  type="number"
                />
              </div>
              <div className="field">
                <label className="label">Current PTO hours:</label>
                <InputField
                  value={currentPtoHours}
                  onChange={setCurrentPtoHours}
                  placeholder="0"
                  type="number"
                />
              </div>
              <div className="field">
                <label className="label">Sick hours added:</label>
                <InputField
                  value={sickHoursAdded}
                  onChange={setSickHoursAdded}
                  placeholder="0"
                  type="number"
                />
              </div>
              <div className="field">
                <label className="label">Current sick hours:</label>
                <InputField
                  value={currentSickHours}
                  onChange={setCurrentSickHours}
                  placeholder="0"
                  type="number"
                />
              </div>

              <div className="mt-3">
                <h2 className="title is-5">Projected End-of-Year Summary</h2>
                <p>Projected End-of-Year PTO hours: <strong>{totalPtoHours}</strong> ({totalPtoDays} days)</p>
                <p>Projected End-of-Year Sick hours: <strong>{totalSickHours}</strong> ({totalSickDays} days)</p>
                <p>Projected Combined PTO + Sick days: <strong>{totalDays}</strong></p>
              </div>
            </div>
          </div>
        </>
      )
    },
    {
      title: 'Incomes',
      content: (
        <SectionTable
          title="Incomes"
          rows={incomeRows}
          addRow={addIncomeRow}
          handleInputChange={handleIncomeChange}
          removeRow={removeIncomeRow}
          fields={[
            { name: 'name', label: 'Income Source', component: TextInput, placeholder: 'ex. Pay Check' },
            { name: 'amount', label: 'Amount', component: (props) => <InputField {...props} formatFn={formatCurrency} maxDecimals={2} />, placeholder: '$' },
            {
              name: 'frequency',
              label: 'Frequency',
              component: SelectInput,
              options: [
                { value: 'monthly', label: 'Monthly' },
                { value: 'annually', label: 'Annually' }
              ]
            }
          ]}
          totalLabel="Total Monthly Income"
          totalValue={totalMonthlyIncome}
        />
      )
    },
    {
      title: 'Expenses',
      content: (
        <SectionTable
          title="Expenses"
          rows={expenseRows}
          addRow={addExpenseRow}
          handleInputChange={handleExpenseChange}
          removeRow={removeExpenseRow}
          fields={[
            { name: 'name', label: 'Expense Item', component: TextInput, placeholder: 'ex. Rent' },
            { name: 'amount', label: 'Amount', component: (props) => <InputField {...props} formatFn={formatCurrency} maxDecimals={2} />, placeholder: '$' },
            {
              name: 'frequency',
              label: 'Frequency',
              component: SelectInput,
              options: [
                { value: 'monthly', label: 'Monthly' },
                { value: 'annually', label: 'Annually' }
              ]
            }
          ]}
          totalLabel="Total Monthly Expense"
          totalValue={totalMonthlyExpense}
        />
      )
    },
    {
      title: 'Assets',
      content: (
        <SectionTable
          title="Assets"
          rows={assetRows}
          addRow={addAssetRow}
          handleInputChange={handleAssetChange}
          removeRow={removeAssetRow}
          fields={[
            { name: 'name', label: 'Name', component: TextInput, placeholder: 'ex. Bank' },
            { name: 'accountType', label: 'Account Type', component: TextInput, placeholder: 'ex. Checking' },
            { name: 'currentValue', label: 'Current Value', component: (props) => <InputField {...props} formatFn={formatCurrency} maxDecimals={2} />, placeholder: '$' },
            { name: 'monthlyDeposit', label: 'Monthly Deposit', component: (props) => <InputField {...props} formatFn={formatCurrency} maxDecimals={2} />, placeholder: '$' },
            { name: 'interest', label: 'Interest', component: (props) => <InputField {...props} formatFn={formatPercentage} maxDecimals={2} />, placeholder: '%' }
          ]}
          totalLabel="Total Assets"
          totalValue={totalAssets}
        />
      )
    },
    {
      title: 'Debts',
      content: (
        <SectionTable
          title="Debts"
          rows={debtRows}
          addRow={addDebtRow}
          handleInputChange={handleDebtChange}
          removeRow={removeDebtRow}
          fields={[
            { name: 'name', label: 'Name', component: TextInput, placeholder: 'ex. Mortgage' },
            { name: 'balance', label: 'Balance', component: (props) => <InputField {...props} formatFn={formatCurrency} maxDecimals={2} />, placeholder: '$' },
            { name: 'interest', label: 'Interest', component: (props) => <InputField {...props} formatFn={formatPercentage} maxDecimals={2} />, placeholder: '%' },
            { name: 'monthlyPayment', label: 'Monthly Payment', component: (props) => <InputField {...props} formatFn={formatCurrency} maxDecimals={2} />, placeholder: '$' }
          ]}
          totalLabel="Total Debts"
          totalValue={totalDebts}
        />
      )
    }
  ];

  return (
    <div className="container">
      <h1 className="title has-text-centered mt-4 mb-4">Financial Assistant</h1>

      <div className="columns is-variable is-5">
        {/* Left Column: User Data & Summary */}
        <div className="column is-one-quarter">
          {/* User Data Box */}
          <div className="box">
            <div className="field">
              <label className="label">Username</label>
              <input
                type="text"
                className="input"
                value={username}
                onChange={e => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="field">
              <label className="label">Password</label>
              <input
                type="password"
                className="input"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="buttons mt-3">
              <button className="button is-primary" onClick={handleSave}>Save Data</button>
              <button className="button is-info" onClick={handleCheckUser}>Load Data</button>
              <button className="button is-danger" onClick={handleDelete}>Delete User</button>
            </div>
          </div>

          {/* Summary Box */}
          <div className="box sticky-summary mt-4">
            <h2 className="title is-5">Summary</h2>
            <p>Total Monthly Income:</p>
            <p className="is-size-5 has-text-weight-bold mb-3">${totalMonthlyIncome}</p>

            <p>Total Monthly Expense:</p>
            <p className="is-size-5 has-text-weight-bold mb-3">${totalMonthlyExpense}</p>

            <p>Monthly Cash Flow:</p>
            <p className="is-size-5 has-text-weight-bold mb-3">
              ${ (parseCurrency(totalMonthlyIncome) - parseCurrency(totalMonthlyExpense))
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')}
            </p>
            <hr />
            <p className="mt-4">Total Assets:</p>
            <p className="is-size-5 has-text-weight-bold mb-3">${totalAssets}</p>

            <p>Total Debts:</p>
            <p className="is-size-5 has-text-weight-bold mb-3">${totalDebts}</p>
          </div>
        </div>

        {/* Right Column: Tabs + Content */}
        <div className="column">
          <nav className="tabs is-toggle is-toggle-rounded is-large is-centered mb-4">
            <ul>
              {tabs.map((tab, i) => (
                <li key={i} className={currentTab === i ? 'is-active' : ''}>
                  <a onClick={() => setCurrentTab(i)}>
                    {tab.title}
                  </a>
                </li>
              ))}
            </ul>
          </nav>

          {/* Display the active tab content */}
          {tabs[currentTab].content}
        </div>
      </div>

      {/* Modal */}
      {showModal && (
        <div className="modal is-active">
          <div className="modal-background" onClick={() => setShowModal(false)}></div>
          <div className="modal-content">
            <div className="box has-text-centered">
              <p>{modalMessage}</p>
              {confirmationCallback ? (
                <div className="buttons is-centered mt-4">
                  <button className="button is-info" onClick={handleModalConfirm}>Yes</button>
                  <button className="button is-warning" onClick={handleModalCancel}>No</button>
                </div>
              ) : (
                <div className="buttons is-centered mt-4">
                  <button className="button is-info" onClick={() => setShowModal(false)}>OK</button>
                </div>
              )}
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={() => setShowModal(false)}></button>
        </div>
      )}
    </div>
  );
}

export default App;
